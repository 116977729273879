import React from 'react'
import './ProjectsStyles.css'

import Shelf from '../../assets/Shelf.png'
import TVStand from '../../assets/TV-Stand.png'
import Sofa from '../../assets/Sofa.jpg'
import TableAndChair from '../../assets/TableAndChair.jpg'
import Wardrobe from '../../assets/Wardrobe.jpg'

function Projects() {
  return (
    <div name='projects' className='projects'>
        <div className="container">
            <h1>Our Projects</h1>
            <div className="img-container">
                <img className='span-3 image-grid-row-2' src={Shelf} alt="/" />
                <img src={TVStand} alt="/" />
                <img src={Sofa} alt="/" />
                <img src={TableAndChair} alt="/" />
                <img src={Wardrobe} alt="/" />
            </div>
        </div>
    </div>
  )
}

export default Projects