// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects {
    width: 100%;
    text-align: center;
    padding: 4rem 1rem;
    background-color: rgb(239, 211, 176);
    margin: auto;
}

.projects h1 {
    padding: 1rem;
}

.img-container {
    --gap:1rem;
    --num-cols:5;
    --row--height: 300px;

    display: grid;
    grid-template-columns: repeat(var(--num-cols), 1fr);
    margin: auto;
    padding: 1rem 0;
    gap: var(--gap);
}

.projects img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* .span-3 {
    grid-column: span 3;
}

.image-grid-row-2 {
    grid-row: span 2;
} */

@media screen and (max-width: 940px) {
    .img-container {
        --num-cols: 2;
        --row-height: 200px;
        --grap: .5rem;
    }

    .span-3 {
        grid-column: span 2;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/components/projects/ProjectsStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,oBAAoB;;IAEpB,aAAa;IACb,mDAAmD;IACnD,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;;;;;;GAMG;;AAEH;IACI;QACI,aAAa;QACb,mBAAmB;QACnB,aAAa;IACjB;;IAEA;QACI,mBAAmB;IACvB;;AAEJ","sourcesContent":[".projects {\n    width: 100%;\n    text-align: center;\n    padding: 4rem 1rem;\n    background-color: rgb(239, 211, 176);\n    margin: auto;\n}\n\n.projects h1 {\n    padding: 1rem;\n}\n\n.img-container {\n    --gap:1rem;\n    --num-cols:5;\n    --row--height: 300px;\n\n    display: grid;\n    grid-template-columns: repeat(var(--num-cols), 1fr);\n    margin: auto;\n    padding: 1rem 0;\n    gap: var(--gap);\n}\n\n.projects img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n/* .span-3 {\n    grid-column: span 3;\n}\n\n.image-grid-row-2 {\n    grid-row: span 2;\n} */\n\n@media screen and (max-width: 940px) {\n    .img-container {\n        --num-cols: 2;\n        --row-height: 200px;\n        --grap: .5rem;\n    }\n\n    .span-3 {\n        grid-column: span 2;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
