import React, {useState} from 'react'
import { BiSearch } from 'react-icons/bi'
import { BsPerson } from 'react-icons/bs'
import { HiOutlineMenuAlt4 } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'
import './NavbarStyles.css'
import { FaFacebook, FaInstagram, FaTwitter, FaPinterest, FaYoutube } from 'react-icons/fa'

import {Link} from 'react-scroll'

function Navbar() {
    const [nav, setNav] = useState(false)
    const handleNav = () => setNav(!nav)
    
    return (
    <div name="WHAT'S NEW" className={nav ? 'navbar navbar-bg' : 'navbar'}>
        <div className={nav ? 'logo dark' : 'logo'}>
            <h2>CJT WHOLESALE.</h2>
        </div>
        <ul className='nav-menu'>
            <Link to="WHAT'S NEW"><li>WHAT'S NEW</li></Link>
            {/* <Link to=''><li>LIVING</li></Link>
            <Link to=''><li>DINING</li></Link>
            <Link to=''><li>BEDROOM</li></Link>
            <Link to=''><li>DECOR</li></Link>
            <Link to=''><li>COLLECTIONS</li></Link>
            <Link to=''><li>INTERIOR DESIGN</li></Link> */}
            <Link to='projects' smooth={true} duration={500} ><li>PROJECTS</li></Link>  
            <Link to='contact' smooth={true} duration={500} ><li>CONTACT US</li></Link>      
        </ul>
        <div className='nav-icons'>
            <BiSearch className='icon' style={{marginRight: '1rem'}}/>
            <BsPerson className='icon'/>
        </div>
        <div className='hamburger' onClick={handleNav}>
            {!nav ? (<HiOutlineMenuAlt4 className='icon' />) : (<AiOutlineClose style={{color: '#000'}} className='icon' />)}
            <HiOutlineMenuAlt4 className='icon'/>
        </div>
        
        <div className={nav ? 'mobile-menu active' : 'mobile-menu'}>
            <ul className='mobile-nav'>
            <Link to="HOME"><li>HOME</li></Link>
            {/* <Link to=''><li>LIVING</li></Link>
            <Link to=''><li>DINING</li></Link>
            <Link to=''><li>BEDROOM</li></Link>
            <Link to=''><li>DECOR</li></Link>
            <Link to=''><li>COLLECTIONS</li></Link>
            <Link to=''><li>INTERIOR DESIGN</li></Link> */}
            <Link to='projects' smooth={true} duration={500} ><li>PROJECTS</li></Link>   
            <Link to='contact' smooth={true} duration={500} ><li>CONTACT US</li></Link>   
            </ul>
            <div className="mobile-menu-bottom">
                <div className="menu-icons">
                    <button>Search</button>
                    <button>Account</button>
                </div>
                <div className="social-icons">
                    <FaFacebook className='icon'/>
                    <FaInstagram className='icon'/>
                    <FaTwitter className='icon'/>
                    <FaPinterest className='icon'/>
                    <FaYoutube className='icon'/>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Navbar

