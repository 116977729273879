// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
  --primary-dark: #5651e5;
  --primary-light: #709dff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 2rem;
}

.container {
  max-width: 1240px;
  margin: auto;
  padding: 0 1 rem;
}

button {
  background-image: linear-gradient(45deg, var(--primary-dark) 0%, var(--primary-light) 100%);
  border: 1px solid var(--primary-dark);
  color: #fff;
  padding: .7rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 948px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }

}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,2FAA2F;EAC3F,qCAAqC;EACrC,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,kCAAkC;AACpC;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,iBAAiB;EACnB;;AAEF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n:root{\n  --primary-dark: #5651e5;\n  --primary-light: #709dff;\n}\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  font-family: \"Poppins\", sans-serif;\n}\n\nul {\n  list-style-type: none;\n}\n\na {\n  text-decoration: none;\n}\n\nh1 {\n  font-size: 3.5rem;\n}\n\nh2 {\n  font-size: 2rem;\n}\n\n.container {\n  max-width: 1240px;\n  margin: auto;\n  padding: 0 1 rem;\n}\n\nbutton {\n  background-image: linear-gradient(45deg, var(--primary-dark) 0%, var(--primary-light) 100%);\n  border: 1px solid var(--primary-dark);\n  color: #fff;\n  padding: .7rem 1.5rem;\n  border-radius: 8px;\n  cursor: pointer;\n  font-family: 'Poppins', sans-serif;\n}\n\n@media screen and (max-width: 948px) {\n  h1 {\n    font-size: 2.5rem;\n  }\n  h2 {\n    font-size: 1.5rem;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
