import React from "react";
import Navbar from './components/navbar/Navbar'
import Hero from "./components/hero/Hero"
import Story from "./components/story/Story"
import Projects from "./components/projects/Projects"
import Contact from "./components/contact/Contact"
import Footer from './components/footer/Footer'


function App() {
  return (
    <div>
      <Navbar/>
      <Hero/>
      <Story/>
      <Projects/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
