import React from 'react'
import './HeroStyles.css'

import Video from '../../assets/interior-design.mp4'

function Hero() {
  return (
    <div className='hero'>
        <video autoPlay loop muted playsInline id='video'>
            <source src={Video} type='video/mp4'/>
        </video>
        <div className="overlay"></div>
        <div className="content">
            <h1>Furniture that's as comfortable as your favorite pajamas</h1>
            <h3>Transform your living space into the ultimate haven of relaxation with our furniture designed for unparalleled comfort.</h3> 
        </div>
    </div>
  )
}

export default Hero



