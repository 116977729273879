import React from 'react'
import './StoryStyles.css'

function Story() {
  return (
    <div className='story'>
        <div className="main-story">
            <div className="header-story">
                <h1>Shop Our Wide Selections of Stylish Furniture</h1>
            </div>
            <div className="desc-story">
            <p>CJT WHOLESALE stands as a testament to the beauty and diversity of Indonesia's natural resources and the skill of its people. With every piece of custom wooden furniture, we invite you to experience the richness of Indonesian culture and the artistry of its master craftsmen.</p>
            </div>
        </div>
        <div className="achievements">
            <div className="heading-style">
                <h3>5+</h3>
                <h4>Year Experience</h4>    
            </div>
            <div className="heading-style">
                <h3>15+</h3>
                <h4>Projects</h4>
            </div>
            <div className="heading-style">
                <h3>25+</h3>
                <h4>Clients</h4>
            </div>
        </div>
    </div>
  )
}

export default Story