import React from 'react'
import './ContactStyles.css'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'

import Warehouse from '../../assets/Warehouse.jpeg'
import { FaWhatsapp } from 'react-icons/fa'
import { MdEmail } from "react-icons/md";

function Contact() {
  const position = { lat: -6.585487, lng: 110.671639 };
  
  const mapStyles = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    }
  ];

  return  (
    <APIProvider apiKey={'AIzaSyBhCl0QicjFC45y6RY_yNdQuTD8RaH48Y4'}>
      <div className="contact">
        <h1>Contact Us</h1>
        <h2>For inquiries or specific questions, get in touch below!</h2>

        {/* Icons for Email and WhatsApp */}
        <div className="contact-icons">
          <a href="mailto: Indomitrabersama@gmail.com" target="_blank" rel="noopener noreferrer" className="icon-link">
            <MdEmail size={50} color="black" className="icon" />
          </a>
          <a href="https://wa.me/+62895605458280" target="_blank" rel="noopener noreferrer" className="icon-link">
            <FaWhatsapp size={50} color="green" className="icon" />
          </a>
        </div>
        <div className="container-contact">
          <div className='map'>
            <Map 
            zoom={19}
            center={position} 
            mapId={'f7828e0eb8258821'}
            options={{ styles: mapStyles}}>
            <Marker position={{lat: -6.585487, lng: 110.671639}} />
            </Map>
            </div>
          <div className="image-container">
            <img src={Warehouse} alt="Warehouse" className="image" />
          </div>
        </div>
      </div>
    </APIProvider>
  );
}

export default Contact

// style={{ height: "50vh", width: "100%" }}