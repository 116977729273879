// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
    width: 100%;
    height: 100vh;
    color: #fff;
    position: relative;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

#video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.hero .content {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin: auto;
    padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/hero/HeroStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;AACX;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".hero {\n    width: 100%;\n    height: 100vh;\n    color: #fff;\n    position: relative;\n}\n\n.overlay {\n    background-color: rgba(0, 0, 0, 0.1);\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n}\n\n#video {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    z-index: -1;\n}\n\n.hero .content {\n    position: absolute;\n    top: 0;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    text-align: center;\n    width: 100%;\n    margin: auto;\n    padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
