// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.story {
    width: 100%;
    text-align: center;
    padding: 2rem 0;
}

.main-story {
    padding: 2rem 0;
}

.header-story {
    padding: 10px 0;
}

.desc-story {
    padding: 1rem 0;
}

.achievements {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 1rem;
}

.heading-style {
    font-size: 1rem;
    line-height: 1.2;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/story/StoryStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".story {\n    width: 100%;\n    text-align: center;\n    padding: 2rem 0;\n}\n\n.main-story {\n    padding: 2rem 0;\n}\n\n.header-story {\n    padding: 10px 0;\n}\n\n.desc-story {\n    padding: 1rem 0;\n}\n\n.achievements {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-evenly;\n    padding: 0 1rem;\n}\n\n.heading-style {\n    font-size: 1rem;\n    line-height: 1.2;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
