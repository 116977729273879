// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    width: 100%;
    padding: 2rem 0;
    background-color: #333;
    color: #fff;
}

.footer h3 {
    color: var(--primary-light);
}

.footer .top, .bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.footer .social {
    display: flex;
    justify-content: space-between;
    width: 280px;
}

.footer .icon {
    color: var(--primary-light);
}

.footer .left, .right {
    display: flex;
    align-items: center;
}

.footer ul {
    display: flex;
}

.footer li {
    padding: .5rem;
}

@media screen and (max-width: 1140px) {
    .footer ul {
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/footer/FooterStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,WAAW;IACX,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".footer {\n    width: 100%;\n    padding: 2rem 0;\n    background-color: #333;\n    color: #fff;\n}\n\n.footer h3 {\n    color: var(--primary-light);\n}\n\n.footer .top, .bottom {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.footer .social {\n    display: flex;\n    justify-content: space-between;\n    width: 280px;\n}\n\n.footer .icon {\n    color: var(--primary-light);\n}\n\n.footer .left, .right {\n    display: flex;\n    align-items: center;\n}\n\n.footer ul {\n    display: flex;\n}\n\n.footer li {\n    padding: .5rem;\n}\n\n@media screen and (max-width: 1140px) {\n    .footer ul {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
